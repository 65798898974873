import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Link, useLocation, useHistory } from "react-router-dom";
const token = (localStorage.getItem("tok"));


function UpdatelistVariant(props) {
    const history = useHistory();
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();
    const idd = query.get("id")
    const p_id = query.get("product_id")

    const [id, setId] = useState([]);
    const [prolist, setProlist] = useState([]);
    const [product_id, setProduct_id] = useState("");
    const [actual_price, setActual_price] = useState("");
    const [discount_price, setDiscount_price] = useState("");
    const [quantity, setQuantity] = useState("");
    const [err, setErr] = useState("");
    const [aerr, setAerr] = useState("");
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [data, setData] = useState(null);
    useEffect(() => {
        axios.post(`${process.env.REACT_APP_API}/admin/getProductVariant`, { variant_id: query.get("id") },
            { headers: { "adminauthtoken": `${token}` } })
            .then((res) => {
                if (res.data.data !== null) {
                    setId(res.data.data.id);
                    setQuantity(res.data.data[0]?.product_variants[0]?.quantity);
                    setActual_price(res.data.data[0]?.product_variants[0]?.actual_price);
                    setDiscount_price(res.data.data[0]?.product_variants[0]?.discount_price);
                } else {
                    props.history.push("/admin/UpdatelistVariant")
                }
            });
    }, [])
    const handleSubmit = () => {
        props.history.push("/admin/CreateProduct/")
        setLoading(true);
        setIsError(false);
        const variant_id = query.get("id");
        axios
            .post(`${process.env.REACT_APP_API}/admin/updateProductVariant`, {
                variant_id,
                product_id: p_id,
                quantity,
                actual_price,
                discount_price
            }, { headers: { "adminauthtoken": `${token}` } })
            .then((res) => {
                setData(res.data);
                setQuantity("");
                setActual_price("");
                setDiscount_price("")
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                setIsError(true);
            })
    }
    useEffect(() => {
        const product_id = query.get("id");
        axios.post(`${process.env.REACT_APP_API}/admin/getProduct`, { product_id }, { headers: { "adminauthtoken": `${token}` } }).then((res) => {
            setProlist(res.data.data);
            setProduct_id(res.data.data[0].id)
        });
    }, [])
    return (
        <div><form onSubmit={handleSubmit}>
            <div className="container p-3 mt-5" >
                <h5 className="d-inline-block mb-3 mt-5">UPDATE PRODUCT VARIANT</h5>
                <div ><label>Product Name</label>
                    {/* <select class="custom-select" value={product_id} onChange={(e) => setProduct_id(e.target.value)}> */}
                    {/* {prolist.map((x) => (
                            < option value={x.id} > {x.product_name}</option>
                        ))} */}
                    <select class="custom-select" onChange={(e) => {
                        if (e.target.value) {
                            setProduct_id(e.target.value)
                        }
                    }}>
                        {prolist.map((x, index) => (
                            <option selected={x.id == p_id} value={p_id}>{x.product_name}</option>
                        ))}
                    </select>
                    <div className="form-group">
                        <label htmlFor="name"> Quantity in Gm</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            placeholder="Ex: 250"
                            value={quantity}
                            maxLength="4"
                            onChange={(e) => setQuantity(e.target.value)}
                            required={true}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="name"> Actual Price</label>
                        <input
                            type="number"
                            className="form-control"
                            id="name"
                            placeholder="actual price"
                            value={actual_price}
                            onChange={(e) => {
                                setActual_price(e.target.value)
                                if (Number(discount_price) < e.target.value) {
                                    setErr("")
                                    setAerr("")
                                }
                                else {
                                    setAerr("Actual price must be Greater than discount price");
                                }
                            }
                            } required={true}
                        /><span style={{ color: "red" }}>{aerr}</span>
                    </div>
                    <div className="form-group">
                        <label htmlFor="name"> Discount Price</label>
                        <input
                            type="number"
                            className="form-control"
                            id="name"
                            placeholder="Discount Price"
                            value={discount_price}
                            onChange={(e) => {
                                setDiscount_price(e.target.value)
                                if (Number(e.target.value) < actual_price) {
                                    setErr("")
                                    setAerr("")
                                }
                                else {
                                    setErr("Discount price must be less than actual price");
                                }
                            }} required={true}
                        /> <span style={{ color: "red" }}>{err}</span>
                    </div>
                    {isError && (
                        <small className="mt-3 d-inline-block text-danger">
                            Something went wrong. Please try again later.
                        </small>
                    )}
                    <button style={{ fontWeight: "bold" }}
                        type="submit"
                        className="btn btn-warning mt-3"
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        {loading ? "Loading..." : "Update"}
                    </button>
                </div>
            </div ></form>
        </div >
    );
}

export default UpdatelistVariant;
