import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const token = (localStorage.getItem("tok"));
axios.defaults.headers.common['adminauthtoken'] = token;

function CreateProduct(props) {
    const [catlist, setCatlist] = useState([]);
    const [type, setProduct_type] = useState([]);
    const [category_id, setCategory_id] = useState("");
    const [category_name, setCategory_name] = useState("");
    const [reset, setReset] = useState(false);
    const [product_name, setProduct_name] = useState("");
    const [product_image, setProduct_image] = useState("");
    const [product_description, setProduct_description] = useState("");
    const [total_stock, setTotal_stock] = useState("");
    const [available_stock, setAvailable_stock] = useState("");
    const [sku, setSku] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [prolist, setProlist] = useState([]);
    const [loading, setLoading] = useState(false);

    const [isError, setIsError] = useState(false);
    const [data, setData] = useState(false);
    function update(id) {
        props.history.push("/admin/UpdateProduct?id=" + id)
    }
    function variant(id) {
        props.history.push("/admin/ListVariant?id=" + id)
    }
    useEffect(() => {
        axios.post(`${process.env.REACT_APP_API}/admin/getProduct`, {}).then((res) => {
            setProlist(res.data.data);
        });
    }, [data]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API}/admin/getCategory`).then((res) => {
            setCatlist(res.data.data.map((data) => ({ label: data.category_name, value: data.id })));
        });
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault()
        // [category_id,
        // category_name,
        // product_name,
        // product_image,
        // product_description,
        setLoading(true);
        const data = {
            type,
            category_id,
            category_name,
            product_name,
            tax: displayName,
            image: product_image,
            product_description,
            sku,

        };

        const formdata = new FormData()
        Object.entries(data).map(data => { formdata.append(data[0], data[1]) })
        axios
            .post(`${process.env.REACT_APP_API}/admin/addProduct`, formdata)
            .then((res) => {
                setData(res.data);
                setReset(!reset);
                setCategory_id("");
                setProduct_type("")
                setProduct_name("");
                setProduct_image("");
                setProduct_description("")
                setSku("")
                setLoading(false);
                axios.post(`${process.env.REACT_APP_API}/admin/getProduct`, {}).then((res) => {
                    setProlist(res.data.data);
                    toast.success("Product Created!", { autoClose: 2000 })
                });
            })
    };
    const updateStatus = (index) => {
        axios.post(`${process.env.REACT_APP_API}/admin/updateProduct`,
            {
                ...prolist[index],
                status: prolist[index].status === "ACTIVE" ? "INACTIVE" : "ACTIVE"
            }
        ).then(res => setData(!data))

        const cate = (catlist.map((C) => (
            C.category_name
        )))
    }
    return (
        <div>
            <form onSubmit={handleSubmit}><ToastContainer />

                <div className="container p-3 mt-5" >
                    <h5 className="d-inline-block mb-3 mt-5">CREATE PRODUCT</h5>
                    <div>
                        <Autocomplete
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    setCategory_name(newValue.label);
                                    setCategory_id(newValue.value)
                                }
                            }}
                            value={category_name}
                            id="combo-box-demo"
                            options={Array.isArray(catlist) ? catlist : []}
                            sx={{ width: "100%" }}
                            renderInput={(params) => <TextField {...params} label="Categories" />}
                        />

                        {/* <div className="form-group">
                        <label htmlFor="name"> Category Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            placeholder="enter name"
                            value={category_id}
                            onChange={(e) => setCategory_id(e.target.value)}
                        />
                    </div> */}
                        <div className="form-group">
                            <label htmlFor="name"> Product Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                placeholder="Enter Name"
                                value={product_name}
                                onChange={(e) => setProduct_name(e.target.value)}
                                required={true}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="name">Product Image</label>
                            <input
                                type="file"
                                className="form-control"
                                id="image"
                                onChange={(e) => {
                                    if (e.target.files)
                                        setProduct_image(e.target.files[0])
                                }}
                                required={true}
                            />
                        </div>

                        {/* <div className="form-group">
                            <label htmlFor="name"> Product image</label>
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                placeholder="Enter Image Url"
                                value={product_image}
                                onChange={(e) => setProduct_image(e.target.value)}
                                required={true}
                            />
                        </div> */}
                        <div className="form-group">
                            <label htmlFor="name"> Product Description</label>
                            <textarea
                                type="text"
                                className="form-control"
                                id="name"
                                placeholder="Enter Description"
                                value={product_description}
                                onChange={(e) => setProduct_description(e.target.value)}
                                required={true}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="name">Product Type</label>
                            <div class="input-group mb-3">
                                <select
                                    value={type}
                                    onChange={(e) => setProduct_type(e.target.value)}
                                    class="form-select"
                                >
                                    <option selected>Product-type</option>
                                    <option value="OFFER">Offer</option>
                                    <option value="NORMAL">Normal</option>
                                </select>
                            </div>
                        </div>




                        <div className="form-group">
                            <label htmlFor="name"> Sku</label>
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                placeholder="Enter Sku"
                                value={sku}
                                onChange={(e) => setSku(e.target.value)}
                                required={true}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="name"> Product Tax</label>
                            {/* <span style={{color:"red",display:"flex",marginBottom:"5px"}}> Maximum Length 15</span> */}
                            <input
                                type="text"
                                maxlength="15"
                                className="form-control"
                                id="displayName"
                                placeholder="Enter Tax (Eg:18)"
                                value={displayName}
                                onChange={(e) => setDisplayName(e.target.value)}
                                required={true}
                            />
                        </div>
                        {isError && (
                            <small className="mt-3 d-inline-block text-danger">
                                Something went wrong. Please try again later.
                            </small>
                        )}
                        <button style={{ fontWeight: "bold" }}
                            type="submit"
                            className="btn btn-block btn-success mt-3"
                            disabled={loading}
                        >
                            {loading ? "Loading..." : "Submit"}
                        </button>
                    </div>
                </div >
            </form>
            <div className="container-fluid p-3 mt-5" >
                <table class="table table-sm mt-3">
                    <thead class="thead" style={{ backgroundColor: "black", color: "white" }}>
                        {/* <th style={{ textAlign: 'center' }}>S No</th> */}
                        <th style={{ textAlign: "center" }}>S No</th>
                        <th style={{ textAlign: "center" }}>Product Image</th>
                        <th style={{ textAlign: "center" }}>Product Name</th>
                        <th style={{ textAlign: "center" }}>Action</th>
                        <th> </th>


                    </thead>
                    <tbody>
                        {prolist.map((x, index) => (
                            <tr>
                                <td style={{ textAlign: 'center' }}> {index + 1}</td>
                                <td style={{ textAlign: "center" }}>
                                    <img src={process.env.REACT_APP_API + x.product_image} width="100" height="100" />
                                </td>
                                <td style={{ textTransform: "capitalize", textAlign: "center" }}>{x.product_name}</td>
                                {/*                                 <td><button disabled={"ACTIVE" == x.status ? false : true} className="btn btn-sm btn-info" onClick={() => update(x.id)} ><span style={{ cursor: "pointer" }}> Update</span></button></td>
                                <td><button disabled={"ACTIVE" == x.status ? false : true} className="btn btn-sm btn-info" onClick={() => variant(x.id)} ><span style={{ cursor: "pointer" }}>View Variant</span></button></td>

                                <td>
                                    <span className={x.status == "ACTIVE" ? "badge badge-danger" : "badge badge-success"} style={{ marginLeft: "10px", cursor: "pointer" }} onClick={() => updateStatus(index)}>{x.status == "ACTIVE" ? <span><i class='bx bxs-trash'> </i>Delete</span> : <span><i class='bx bx-undo'>  </i>  Undo</span>} </span>
                                </td> */}


                                <td style={{ textAlign: "center" }}>
                                    <span disabled={"ACTIVE" == x.status ? false : true} className="badge badge-success" style={{ marginLeft: "10px", cursor: "pointer" }} onClick={() => variant(x.id)}> <i class='bx bx-show'>  </i>  View</span>
                                    <span disabled={"ACTIVE" == x.status ? false : true} className="badge badge-warning" style={{ marginLeft: "10px", cursor: "pointer" }} onClick={() => update(x.id)}> <i class='bx bx-edit-alt'></i>  Update</span>
                                    <span className={x.status == "ACTIVE" ? "badge badge-danger" : "badge badge-success"} style={{ marginLeft: "10px", cursor: "pointer" }} onClick={() => updateStatus(index)}>{x.status == "ACTIVE" ? <span><i class='bx bxs-trash'> </i>Delete</span> : <span><i class='bx bx-undo'>  </i>  Undo</span>} </span>
                                </td>
                                <td>
                                </td>


                                {/* {x.status == "ACTIVE" ? "Active" : "InActive"} */}
                            </tr>
                        ))}
                        {prolist.length == 0 && (
                            <tr>
                                <td className="text-center" colSpan="4">
                                    <b>No data found to display</b>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div >
    );
}
export default CreateProduct;
