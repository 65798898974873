import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Link, useLocation, useHistory } from "react-router-dom";
const token = (localStorage.getItem("tok"));

function UpdateProduct(props) {
    const history = useHistory();
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();

    const [catlist, setCatlist] = useState([]);
    const [prolist, setProlist] = useState([]);
    const [product_type, setProduct_type] = useState("");
    const [category_id, setCategory_id] = useState("");
    const [category_name, setCategory_name] = useState("");
    const [product_name, setProduct_name] = useState("");
    const [product_image, setProduct_image] = useState("");
    const [product_description, setProduct_description] = useState("");
    const [sku, setSku] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [data, setData] = useState(false);
    const id = query.get("id");


    useEffect(() => {
        axios.post(`${process.env.REACT_APP_API}/admin/getProduct`, { id },
            { headers: { "adminauthtoken": `${token}` } })
            .then((res) => {
                res.data.data.forEach(element => {
                    if (element.id == id) {
                        setCategory_id(element.category_id);
                        setCategory_name(element.category_name);
                        setProduct_image(element.product_image);
                        setProduct_name(element.product_name);
                        setProduct_description(element.product_description);
                        setSku(element.sku);
                        setDisplayName(element.tax)
                    }
                });
            }
            );
    }, [])

    const handleSubmit = () => {
        // props.history.push("/admin/CreateProduct/")
        setLoading(true);
        setIsError(false);
        const data = {
            id,
            category_id,
            product_type,
            category_name,
         image:   product_image,
            product_name,
            product_description,
            sku,
            tax: displayName,
        };
       
        const formdata = new FormData()
        Object.entries(data).map(data=>{ formdata.append(data[0],data[1])})
        axios
            .post(`${process.env.REACT_APP_API}/admin/updateProduct`, formdata, { headers: { "adminauthtoken": `${token}` } })
            .then((res) => {
                setProduct_type("");
                setLoading(false);
                toast.success("Product Created!", { autoClose: 2000 })
                props.history.push("/admin/CreateProduct/")
            })
            .catch((err) => {
                setLoading(false);
                setIsError(true);
            });
    };
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API}/admin/getCategory`, { headers: { "adminauthtoken": `${token}` } }).then((res) => {
            setCatlist(res.data.data);
        });
    }, [])
    return (
        <div><ToastContainer />
            <div className="container p-3 mt-5" >
                <h5 className="d-inline-block mb-3 mt-5">UPDATE PRODUCT</h5>
                <div >

                    <select class="custom-select" onChange={(e) => {
                        if (e.target.value) {
                            setCategory_id(catlist[e.target.value].id)
                            setCategory_name(catlist[e.target.value].category_name)
                        }
                    }}>

                        {catlist.map((x, index) => (
                            // <option value={x.id}>{x.category_name}</option>
                            <option selected={x.id === category_id} value={index}>{x.category_name}</option>
                        ))}
                    </select>
                    {/* <label htmlFor="name"> Category Id </label> <p>{category_id}</p> */}
                    {/* <label htmlFor="name">   Product ID</label>  <p>{id}</p> */}
                    <div className="form-group">
                        <label htmlFor="name"> Product Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            placeholder="Enter Name"
                            value={product_name}
                            onChange={(e) => setProduct_name(e.target.value)}
                        />
                    </div>

                    
                <div className="form-group">
          <label htmlFor="name"> Update Product Image</label>
          <input
            type="file"
            className="form-control"
            id="image"
            onChange={(e) =>{ if(e.target.files)
                setProduct_image(e.target.files[0]   )
              }}
            required={true}
          />
        </div>
                    {/* <div className="form-group">
                        <label htmlFor="name"> Product image</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            placeholder="Enter Image Url"
                            value={product_image}
                            onChange={(e) => setProduct_image(e.target.value)}
                        />
                    </div> */}
                    <div className="form-group">
                        <label htmlFor="name"> Product Description</label>
                        <textarea
                            type="text"
                            className="form-control"
                            id="name"
                            placeholder="Enter Description"
                            value={product_description}
                            onChange={(e) => setProduct_description(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="name"> Sku</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            placeholder="Enter Sku"
                            value={sku}
                            onChange={(e) => setSku(e.target.value)}
                        />
                    </div>

                    {isError && (
                        <small className="mt-3 d-inline-block text-danger">
                            Something went wrong. Please try again later.
                        </small>
                    )}
                           <div className="form-group">
                            <label htmlFor="name"> Product Tax</label>
                            {/* <span style={{color:"red",display:"flex",marginBottom:"5px"}}> Maximum Length 15</span> */}
                            <input
                                type="text"
                                maxlength="15"
                                className="form-control"
                                id="displayName"
                                placeholder="Enter Tax (Eg:18)"
                                value={displayName}
                                onChange={(e) => setDisplayName(e.target.value)}
                                required={true}
                            />
                        </div>
<div className="form-group">
          <label htmlFor="name">Product Type</label>
          <div class="input-group mb-3">
            <select
              value={product_type}
              onChange={(e) => setProduct_type(e.target.value)}
              class="form-select"
            >
              <option selected>Product-type</option>
              <option value="OFFER">Offer</option>
              <option value="NORMAL">Normal</option>
            </select>
          </div>
        </div>





                    <button style={{ fontWeight: "bold" }}
                        type="submit"
                        className="btn btn-warning mt-3"
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        {loading ? "Loading..." : "Submit"}
                    </button>
                </div>
            </div >
        </div >
    );
}

export default UpdateProduct;
