import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch,Router, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.3.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import AdminLayout from "layouts/Admin.js";
import Createcategory from './views/Components/Createcategory'
import UpdateCategory from './views/Components/UpdateCategory'
import CreateProduct from './views/Components/CreateProduct'
import CreateProductVariant from './views/Components/CreateProductVariant'
import UpdatelistVariant from './views/Components/UpdatelistVariant'
import UpdateProduct from './views/Components/UpdateProduct'
import ListVariant from './views/Components/ListVariant'
import UpdateBanner from './views/Components/UpdateBanner'
import CreateAppBanner from './views/Components/CreateAppBanner'
import UpdateAppBanner from './views/Components/UpdateAppBanner'
import Login from './views/Components/Login'
import Customerlist from './views/Components/Customerlist'
import list from './views/Components/list'
import Orders from './views/Components/Orders'
import viewOrders from './views/Components/viewOrders'
import Notfound from './views/Components/Notfound'
const token = (localStorage.getItem("tok"));
ReactDOM.render(
  <BrowserRouter>

    <Switch>

      {token ? <>
        {/* <Route path="/admin" render={(props) => <AdminLayout {...props} />} /> */}
        <Route path="/CreateCategory" component={Createcategory} />
        <Route path="/CreateProduct" component={CreateProduct} />
        <Route path="/UpdateCategory" component={UpdateCategory} />
        <Route path="/CreateProductVariant" component={CreateProductVariant} />
        <Route path="/UpdateProduct" component={UpdateProduct} />
        <Route path="/ListVariant" component={ListVariant} />
        <Route path="/UpdatelistVariant" component={UpdatelistVariant} />
        <Route path="/UpdateBanner" component={UpdateBanner} />
        <Route path="/CreateAppBanner" component={CreateAppBanner} />
        <Route path="/UpdateAppBanner" component={UpdateAppBanner} />
        <Route path="/Customerlist" component={Customerlist} />
        <Route path="/list" component={list} />
        {/* <Route path="/Orders" component={Orders} /> */}
        <Route path="/viewOrders" component={viewOrders} />
        <Route path="/" render={(props) => <AdminLayout {...props} />} />

       

      </>
        :
        <>
          <Route path="/Login" exact={true} component={Login} />
          <Redirect path="/" to="/Login" />

        </>
      }

    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
