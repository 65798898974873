import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const token = (localStorage.getItem("tok"));

axios.defaults.headers.common['adminauthtoken'] = token;
function Createcategory(props) {
    const [category_name, setCategory_name] = useState("");
    const [category_image, setCategory_image] = useState("");
    const [catlist, setCatlist] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [data, setData] = useState(false);
    const [err, setErr] = useState("");
    function update(id) {
        props.history.push("/admin/UpdateCategory?id=" + id)
    }
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API}/admin/getCategory`).then((res) => {
            setCatlist(res.data.data);
        });
    }, [data]);
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true);
        setIsError(false);
        const data = {
            category_name,
            category_image,
        };
        const formdata = new FormData()
        formdata.append("image",category_image)
        formdata.append("category_name",category_name)

        axios
        
            .post(`${process.env.REACT_APP_API}/admin/addCategory`, formdata)
            
            .then((res) => {
                setData(res.data);
                setCategory_name("");
                setCategory_image("");
               
                setLoading(false);
                axios.get(`${process.env.REACT_APP_API}/admin/getCategory`).then((res) => {
                    setCatlist(res.data.data);
                    toast.success("Category Created!", { autoClose: 2000 })
                });
            })
            .catch((err) => {
                setLoading(false);
                setIsError(true);
            });
    };

    const updateStatus = (index) => {

        axios.post(`${process.env.REACT_APP_API}/admin/updateStatus`, {
            ...catlist[index], status: catlist[index].status === "ACTIVE" ? "INACTIVE" : "ACTIVE"
            }
        ).then(res => setData(!data))
    }


    return (
        <div><form onSubmit={handleSubmit}>
            <ToastContainer />
            <div className="container p-3 mt-5 pt-3">
                <h5 className="d-inline-block mb-3 mt-5" >CREATE CATEGORY</h5>
                <div >
                    <div className="form-group">
                        <label htmlFor="name">Category Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            placeholder="Enter Name"
                            value={category_name}
                            onChange={(e) => setCategory_name(e.target.value)}
                            required={true}
                        /> <span style={{ color: "red" }}> {err}</span>
                    </div>


                    {/* <div className="form-group">
                        <label htmlFor="name"> Category image</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            placeholder="Enter Image Url"
                            value={category_image}
                            onChange={(e) => setCategory_image(e.target.value)}
                            required={true}
                        />
                    </div> */}

    <div className="form-group">
          <label htmlFor="name">Category Image</label>
          <input
            type="file"
            className="form-control"
            id="image"
            onChange={(e) =>{ if(e.target.files)
                setCategory_image(e.target.files[0]   )
              }}
            required={true}
          />
        </div>






                    {isError && (
                        <small className="mt-3 d-inline-block text-danger">
                            Something went wrong. Please try again later.
                        </small>
                    )}
                    <button style={{ fontWeight: "bold" }}
                        type="submit"
                        className="btn btn-success btn-block"
                        disabled={loading}
                    >
                        {loading ? "Loading..." : "Submit"}
                    </button>
                </div>
            </div >
        </form>
            <div className="m-5">
                <table class="table table-sm"  >
                    <thead class="thead" style={{ backgroundColor: "black", color: "white" }}>
                        <th>S No</th>
                        <th>Category Image </th>
                        <th> Category Name</th>
                        <th> Action</th>
                    </thead>
                    <tbody>
                        {catlist.map((x, index) => (
                            <tr>
                                <td>{index + 1}</td>
                                <td>
                                    <img src={process.env.REACT_APP_API + x.category_image} width="100" height="100" />
                                </td>
                                <td style={{ textTransform: "capitalize" }}>{x.category_name}</td>
                                {/* <td>  {x.status == "ACTIVE" ? <span class="badge  badge-success">Active</span> : null}
                                    {x.status == "INACTIVE" ? <span class="badge  badge-warning">Inactive</span> : null}
                                    {x.status == "TERMINATED" ? <span class="badge  badge-danger">Terminated</span> : null}
                                </td> */}
                                <td><span className={x.status == "ACTIVE" ? "badge badge-danger" : "badge badge-success"} style={{ marginRight: "10px" }} onClick={() => updateStatus(index)}>{x.status == "ACTIVE" ? <span style={{ cursor: "pointer" }}><i class='bx bxs-trash' > </i> Delete</span> : <span style={{ cursor: "pointer" }}><i class='bx bx-undo'>  </i>  Undo</span>} </span>
                                    <span className="badge badge-warning" onClick={() => update(x.id)} style={{ cursor: "pointer" }}><i class='bx bx-edit-alt'> </i> Update</span></td>
                            </tr>
                        ))}
                        {catlist.length == 0 && (
                            <tr>
                                <td className="text-center" colSpan="4">
                                    <b>No data found to display</b>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div >
    );
}

export default Createcategory;
