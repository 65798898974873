import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { fontWeight } from "@mui/system";
const token = (localStorage.getItem("tok"));

axios.defaults.headers.common['adminauthtoken'] = token;
function CreateAppBanner(props) {
    const [image, setImage] = useState("");
    const [type, setProduct_type] = useState("");
    const [loading, setLoading] = useState(false);
    const [appbannerlist, setAppBannerlist] = useState([]);
    const [isError, setIsError] = useState(false);
    const [data, setData] = useState(false);

    function update(id) {
        props.history.push("/admin/UpdateAppBanner?id=" + id)
    }
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API}/admin/getAppBanners`).then((res) => {
            setAppBannerlist(res.data.data);
        });
    }, [data]);
    const handleSubmit = () => {
        setLoading(true);
        setIsError(false);
        const data = {
            type,
            image,
        };
        const formdata = new FormData()
        formdata.append("image",image)
        formdata.append("type",type)
        axios
            .post(`${process.env.REACT_APP_API}/admin/addAppBanners`, formdata)
            .then((res) => {
                setData(res.data);
                setImage("");
                setLoading(false);
                axios.get(`${process.env.REACT_APP_API}/admin/getAppBanners`).then((res) => {
                    setAppBannerlist(res.data.data);
                });
            })
            .catch((err) => {
                setLoading(false);
                setIsError(true);
            });
    };
    const updateStatus = (index) => {
     
        axios.post(`${process.env.REACT_APP_API}/admin/removeAppBanners`,
            {
                
                image_id: appbannerlist[index].id,
                status: appbannerlist[index].status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
                type:appbannerlist[index].appbanner_type,

            }
        ).then(res => setData(!data))
    }
    const updateType = (index) => {
     
        axios.post(`${process.env.REACT_APP_API}/admin/removeAppBanners`,
            {
                
                image_id: appbannerlist[index].id,
                status: appbannerlist[index].status,
                type:appbannerlist[index].appbanner_type==="NORMAL"?"OFFER":"NORMAL",

            }
        ).then(res => setData(!data))
    }
    return (
        <div>
            <div className="container p-3 mt-5" >
                <h5 className="d-inline-block mb-3 mt-5">CREATE APP BANNER</h5>
                <div >
                    {/* <div className="form-group">
                        <label htmlFor="name">Banner Image</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            placeholder="Enter Image Url"
                            value={image}
                            onChange={(e) => setImage(e.target.value)}
                        />
                    </div> */}

<div className="form-group">
          <label htmlFor="name">Baner Image</label>
          <input
            type="file"
            className="form-control"
            id="image"
            onChange={(e) =>{ if(e.target.files)
                setImage(e.target.files[0]   )
              }}
            required={true}
          />
        </div>
        <div class="alert  text-white p-3" style={{background:"#000",fontWeight:"500"}} role="alert">
 Image Should be 1920 x 1080  ( Or )  1280 x 720
</div>

                    {/* <div className="form-group">
          <label htmlFor="name">Product Type</label>
          <div class="input-group mb-3">
            <select
              value={type}
              onChange={(e) => setProduct_type(e.target.value)}
              class="form-select"
            >
              <option selected>Product-type</option>
              <option value="OFFER">Offer</option>
              <option value="NORMAL">Normal</option>
            </select>
          </div>
        </div> */}


                    {/* {isError && (
                        <small className="mt-3 d-inline-block text-danger">
                            Something went wrong. Please try again later.
                        </small>
                    )} */}
                    <button style={{ fontWeight: "bold" }}
                        type="submit"
                        className="btn btn-block btn-success mt-3"
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        {loading ? "Loading..." : "Submit"}
                    </button>

                </div>
            </div >
          
            <div className="container-fluid p-3 mt-5" >
                <table class="table table-sm mt-3">
                    <thead class="thead" style={{ backgroundColor: "black", color: "white" }}>
                        <th style={{ textAlign: "center" }}>S No</th>
                        <th style={{ textAlign: "center" }}>App Banner Image </th>
                        <th style={{ textAlign: "left" }}> Product Type</th>
                        <th style={{ textAlign: "left" }}> Status</th>
                        {/* <th> Action</th> */}
                    </thead>
                    <tbody>
       
                        {appbannerlist.map((x, index) => (
                            
                            <tr>
                               
                                <td style={{ textAlign: "center" }}>{index + 1}</td>
                                <td style={{ textAlign: "center" }}>
                                    <img src={process.env.REACT_APP_API + x.banner_image} style={{ height: "100px", width: "150px" }} />
                                </td>

                                {/* <td>  {x.status == "ACTIVE" ? <span class="badge badge-pill badge-success">Active</span> : null}
                                    {x.status == "INACTIVE" ? <span class="badge badge-pill badge-warning">Inactive</span> : null}
                                    {x.status == "TERMINATED" ? <span class="badge badge-pill badge-danger">Terminated</span> : null}
                                </td> */}

                                  <td> <span className={x.appbanner_type === "NORMAL" ? "badge badge-light" : "badge badge-warning"} style={{ marginLeft: "10px" }} onClick={() => updateType(index)}><span style={{ cursor: "pointer" }}> {x.appbanner_type=== "NORMAL"
                                    ? <span style={{ cursor: "pointer" }}> Normal</span> : <span style={{ cursor: "pointer" }}>  Offer</span>} </span></span></td>   

                                    <td> <span className={x.status === "ACTIVE" ? "badge badge-danger" : "badge badge-success"} style={{ marginLeft: "10px" }} onClick={() => updateStatus(index)}><span style={{ cursor: "pointer" }}> {x.status ==="ACTIVE"
                                        ? <span style={{ cursor: "pointer" }}><i class='bx bxs-trash' > </i> Delete</span> : <span style={{ cursor: "pointer" }}><i class='bx bx-undo'>  </i>  Undo</span>} </span></span></td>
                               




                            </tr>
                        ))}
                        {appbannerlist.length == 0 && (
                            <tr>
                                <td className="text-center" colSpan="4">
                                    <b>No data found to display</b>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div >
    );
}

export default CreateAppBanner;
