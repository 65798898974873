import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Link, useLocation, useHistory, } from "react-router-dom";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
} from "reactstrap";
import axios from "axios";
import { useParams } from "react-router";
const token = localStorage.getItem("tok");

axios.defaults.headers.common["adminauthtoken"] = token;
function ViewOrders() {
  const [list, setList] = useState("");
  const [load, setLoad] = useState(true);
  const [error, setError] = useState("");
  const [address, setAddress] = useState([]);
  const history = useHistory();
  const { id } = useParams();
  const [orderId, setOrderId] = useState({
    orderId: id,
  });
  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API}/admin/order`, orderId)
      .then((res) => {
        setList(res.data.data);
        console.log("response",res.data.data);
        setLoad(false);
        if (res.data.data == "No Orders Available") {
          setError(<h3>No Orders Available</h3>);
        }
      });
  }, []);
  return load ? (
    <> </>
  ) : (
    <Row >
      <Card className="col-6 pt-5" style={{marginTop:"100px"}}>
      
          <CardTitle tag="h4" style={{textAlign:"center",marginLeft:"150px"}}>Profile </CardTitle>
     
        <CardBody >
          <Table responsive>
            <thead className="text-secondary">
                <tr>
                <th style={{ textAlign: "right" }}>Name</th>
                  <th style={{ textAlign: "center", textTransform: "capitalize" }}>{list.fetchProfile.customername}</th>
                </tr>
               
             <tr>
                  <th style={{ textAlign: "right" }}>email</th>
                  <th style={{ textAlign: "center", textTransform: "lowercase" }}>{list.fetchProfile.email}</th>    
               </tr>
              <tr> 
                 <th style={{ textAlign: "right" }}>phone</th>
                  <th style={{ textAlign: "center", textTransform: "capitalize" }}>{list.fetchProfile.phone}</th>
              </tr>
            </thead>
          </Table>
        </CardBody>
      </Card>
      <Card  className="col-6 pt-5" style={{marginTop:"100px",}}>
      
          <CardTitle tag="h4" style={{textAlign:"center",}}>Address</CardTitle>
        
        <CardBody >
          <Table responsive>
            <thead className="text-secondary">
               <tr>
                  <th style={{ textAlign: "left" }}>Landmark</th>
                  <th style={{ textAlign: "center" }}>{list.fetchAddress[0].landmark}</th>
                </tr>
               <tr>
                  <th style={{ textAlign: "left" }}>Street</th>
                  <th style={{ textAlign: "center" }}>{list.fetchAddress[0].street}</th>                
                </tr>
               <tr>
                  <th style={{ textAlign: "left" }}>City</th>
                  <th style={{ textAlign: "center" }}>{list.fetchAddress[0].city}</th>
                </tr>
               <tr>
                  <th style={{ textAlign: "left" }}>State</th>
                  <th style={{ textAlign: "center" }}>{list.fetchAddress[0].state}</th>    
                </tr>
               <tr>
                  <th style={{ textAlign: "left" }}>Zipcode</th>
                  <th style={{ textAlign: "center" }}>{list.fetchAddress[0].zipcode}</th>    
                </tr>
            </thead>
          </Table>
        </CardBody>
      </Card>
    

      <Card  style={{textAlign:"center"}}>
        <CardHeader>
          <CardTitle tag="h4">Product Details</CardTitle>
        </CardHeader>
        <CardBody>
          <Table responsive>
            <thead className="text-secondary">
              <tr>
                <th style={{ textAlign: "center",textTransform:"capitalize" }}>S.No</th>
                <th style={{ textAlign: "center",textTransform:"capitalize" }}>Category Name</th>
                <th style={{ textAlign: "center",textTransform:"capitalize" }}> Product Image</th>
                <th style={{ textAlign: "center",textTransform:"capitalize" }}>Product Name</th>
                <th style={{ textAlign: "center",textTransform:"capitalize" }}>Quantity</th>
                <th style={{ textAlign: "center",textTransform:"capitalize" }}>Total Price</th>
              </tr>
            </thead>
            <tbody>
              {list.fetchCart?.map((P,index)=>(
                <tr>
                  <td style={{ textAlign: "center",textTransform:"capitalize" }}>{++index}</td>
                  <td style={{ textAlign: "center",textTransform:"capitalize" }}>{P.categoryName}</td>
                  <td style={{ textAlign: "center", textTransform: "capitalize" }}><img src={process.env.REACT_APP_API + P.productImage} style={{ width: "100px" }} /></td>
                  <td style={{ textAlign: "center",textTransform:"capitalize" }}>{P.productName}</td>                
                  <td style={{ textAlign: "center",textTransform:"capitalize" }}>{P.quantity}</td>    
                  <td style={{ textAlign: "center",textTransform:"capitalize" }}>₹{P.total_price}/-</td>    
                </tr>   
                  ))}  
            </tbody>
          </Table>
        </CardBody>
      </Card>
      </Row> 
   
  );
}

export default ViewOrders;
