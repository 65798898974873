import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Link, useLocation } from "react-router-dom";

import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col,
} from "reactstrap";

const token = (localStorage.getItem("tok"));
function ListVariant(props) {
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();
    const product_id = query.get("id");
    
    const [variant, setVariant] = useState([]);
    useEffect(() => {

        axios.post(`${process.env.REACT_APP_API}/admin/getProductVariant`, { product_id }, { headers: { "adminauthtoken": `${token}` } }).then((res) => {
            if (res.data.data !== null) {
                setVariant(res.data.data);
            }
            else {

            }
        });
    }, []);
    function update(id,id2) {
        props.history.push("/admin/UpdatelistVariant?id=" + id+ "&product_id="+id2)
    }
    function deletee(id){
        axios
        .post(`${process.env.REACT_APP_API}/admin/deleteProductVariant`, {
            id,
 }, { headers: { "adminauthtoken": `${token}` } }).then(()=>{
    

        axios.post(`${process.env.REACT_APP_API}/admin/getProductVariant`, { product_id }, { headers: { "adminauthtoken": `${token}` } }).then((res) => {
            if (res.data.data !== null) {
                setVariant(res.data.data);
            }
        });
    
 })
    }

    return (
        <>
             <div className="container p-3 mt-5">
              
             
          {variant.length !==0  ?   
          <div>
              <h5 className="d-inline-block mb-5 mt-5">LIST VARIANT PRODUCT</h5>
        
              <table id="example" class="table table-striped mb-5" style={{ width: "100%" }}>
                    <thead>
                        <tr>
                            <th style={{ textAlign: 'center' }}>S No</th>
                            <th style={{ textAlign: 'center' }}>Quantity</th>
                            <th style={{ textAlign: 'center' }}>Actual Price</th>
                            <th style={{ textAlign: 'center' }}>Discount Price</th>
                            <th style={{ textAlign: 'center' }}>Action</th>
                        </tr>
                    </thead>
                    {Array.isArray(variant) && variant.length !==0 ? variant?.map((x, index) => (
                        <thead>
                            <tr>
                                <td style={{ textAlign: 'center' }}>{index + 1}</td>
                                <td style={{ textAlign: 'center' }}>{x.quantity} gm</td>
                                <td style={{ textAlign: 'center' }}>₹{x.actual_price}</td>
                                <td style={{ textAlign: 'center' }}>₹{x.discount_price}</td>
                                <td style={{ textAlign: 'center' }}>
                                    <span className="badge badge-warning" onClick={() => update(x.id, product_id)}> <span style={{ cursor: "pointer" }}><i class='bx bx-edit-alt'> </i>Update</span></span>
                                <span className= "badge badge-danger ms-5" style={{cursor:"pointer"}} onClick={() => deletee(x.id, product_id) }><span><i class='bx bxs-trash'> </i>Delete</span></span>
                                </td>
                            </tr>
                        </thead>
                    )) : false }

                </table>
                </div>
            : 
            <h1 className="pt-5" style={{display:"flex",justifyContent:"center"}}>"No Variant Found" </h1>}
            </div>  

        </>
    );
}
export default ListVariant;