import React from 'react'
import CreateProductVariant from './CreateProductVariant'

function ProducVariant() {
    return (
        <div>
            <CreateProductVariant />
        </div>
    )
}

export default ProducVariant
