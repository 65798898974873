
import Dashboard from "views/Dashboard.js";
import TableList from "views/Allcustomers.js";
import Createcategory from "views/Components/Createcategory.js";
import ProducVariant from "views/Components/ProducVariant";
import CreateAppBanner from "views/Components/CreateAppBanner.js";
import UpdateCategory from "views/Components/UpdateCategory.js";
import CreateProduct from "views/Components/CreateProduct.js";
import Customerlist from "views/Components/Customerlist.js";
import UpdateProduct from "views/Components/UpdateProduct.js";
import UpdateBanner from "views/Components/UpdateBanner.js";
import UpdateAppBanner from "views/Components/UpdateAppBanner.js";
import UpdatelistVariant from "views/Components/UpdatelistVariant.js";
import ListVariant from "views/Components/ListVariant.js";
import Orders from "views/Components/Orders.js";
import viewOrders from "views/Components/viewOrders.js";
import Notfound from "views/Components/Notfound.js";


var routes = [
  {
    sidebar: true,
    exact: true,
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "/admin",
  },
  {
    sidebar: true,
    path: "/Allcustomers",
    name: "Customer List",
    icon: "nc-icon nc-single-02",
    component: TableList,
    layout: "/admin",
  },
  {
    sidebar: true,
    path: "/Createcategory",
    name: "Category",
    icon: "nc-icon nc-box-2",
    component: Createcategory,
    layout: "/admin",
  },
  {
    sidebar: true,
    path: "/CreateProduct",
    name: "Product",
    icon: "nc-icon nc-app",
    component: CreateProduct,
    layout: "/admin",
  },
  {
    sidebar: true,
    path: "/ProducVariant",
    name: "Product Variant",
    icon: "nc-icon nc-bullet-list-67",
    component: ProducVariant,
    layout: "/admin",
  },
  
  {
    sidebar: true,
    path: "/CreateAppBanner",
    name: "AppBanner",
    icon: "nc-icon nc-album-2",
    component: CreateAppBanner,
    layout: "/admin",
  },
  {
    sidebar: true,
    path: "/Orders",
    name: "Orders",
    icon: "nc-icon nc-bullet-list-67",
    component: Orders,
    layout: "/admin",
  },

  {
    sidebar: false,
    path: "/UpdateCategory",
    component: UpdateCategory,
    layout: "/admin",
  },
  {
    sidebar: false,
    path: "/Customerlist",
    component: Customerlist,
    layout: "/admin",
  },
  {
    sidebar: false,
    path: "/UpdateProduct",
    component: UpdateProduct,
    layout: "/admin",
  },

  {
    sidebar: false,
    path: "/UpdateBanner",
    component: UpdateBanner,
    layout: "/admin",
  },
  {
    sidebar: false,
    path: "/UpdateAppBanner",
    component: UpdateAppBanner,
    layout: "/admin",
  },
  {
    sidebar: false,
    path: "/UpdatelistVariant",
    component: UpdatelistVariant,
    layout: "/admin",
  },
  {
    sidebar: false,
    path: "/ListVariant",
    component: ListVariant,
    layout: "/admin",
  },
  {
    sidebar: false,
    path: "/viewOrders/:id",
    component: viewOrders,
    layout: "/admin",
  },
  {
    sidebar: false,
    path: "/",
    component: Dashboard,
    layout: "/admin",
  },


];
export default routes;
