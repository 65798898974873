import React, { useState } from 'react'
import axios from 'axios'
import loginsvg from '../../login.svg'
import { BrowserRouter as Router, Route, Link, useLocation, Redirect, useHistory } from "react-router-dom";
const token = (localStorage.getItem("tok"));
function Login(props) {
    // { token === null ? history.push("/") : <Redirect to="/admin/dashboard" /> }
    let history = useHistory();
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [loading, setLoading] = useState("")
    const [iserror, setIsError] = useState("")
    const [data, setData] = useState("")
    const handleSubmit = () => {
        setLoading(true);
        setIsError(false);
        const data = {
            email,
            password,
        };
        axios
            .post(`${process.env.REACT_APP_API}/admin/login`, data)
            .then((response) => {
                setData(response.data);
                setEmail("");
                setPassword("");
                setLoading(false);
                    
                if (response.data.data == "No Admin Found") {

                    setIsError(`${response.data.data}`)
                }
                else if (response.data.data == "Wrong Password. Try Again!") {

                    setIsError(`${response.data.data}`)
                }
                else if (response.data.data == "Verify Account") {
                    setIsError(`Please ${response.data.data}`)
                }
                if (response.data.data.token != undefined) {
                    localStorage.setItem("tok", response.data.data.token)
                    window.location.href="/admin/dashboard";
                }
            })
    };
    return (
        <div className="container d-flex align-items-center min-vh-100">
            <div className="row">
                <div className="col-6">
                    <img src={loginsvg} width="100%" />
                </div>
                <div className="col-6">
                    <form className="container mt-3">
                        <h3 className="row mb-0" style={{ color: "#28a745", fontWeight: "bolder", display: "flex", justifyContent: "center" }}>UYIRTHEENI ADMIN-PANEL</h3>
                        <br/>
                        <h3>Log in</h3>
                        <div className="form-group ">
                            <label>Email </label>
                            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="Enter Email" required />
                        </div>
                        <div className="form-group">
                            <label>Password</label>
                            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="form-control" placeholder="Enter Password" />
                        </div>
                        <span style={{ color: "red", }}>{iserror}</span>

                        <button type="button" onClick={handleSubmit} className="btn btn-success btn-lg btn-block">Sign in</button>
                    </form>
                </div>
            </div>
        </div>

    )
}

export default Login
