import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Link, useLocation, useHistory } from "react-router-dom";
const token = (localStorage.getItem("tok"));

function UpdateCategory(props) {
    const history = useHistory();
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();
    const [status, setStatus] = useState("");
    const [category_name, setCategory_name] = useState("");
    const [category_image, setCategory_image] = useState("");
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [data, setData] = useState(null);
    const handleSubmit = () => {

        setLoading(true);
        setIsError(false);
        const data = {
            id,
            category_name,
            category_image,
        };
        const formdata = new FormData()
        formdata.append("image",category_image)
        formdata.append("category_name",category_name)
        formdata.append("id",id)
        axios
            .post(`${process.env.REACT_APP_API}/admin/updateCategory`, formdata, { headers: { "adminauthtoken": `${token}` } })
            .then((res) => {
                setData(res.data);
                setCategory_name("");
                setCategory_image("");
                setLoading(false);
                props.history.push("/admin/Createcategory")
            })
            .catch((err) => {
                setLoading(false);
                setIsError(true);
            });
    };
    const id = query.get("id");
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API}/admin/getCategory`,
            { headers: { "adminauthtoken": `${token}` } })
            .then((res) => {
                res.data.data.forEach(element => {
                    if (element.id == id) {
                        setCategory_image(element.category_image);
                        setCategory_name(element.category_name);
                    }
                });
            });
    }, [])

    return (
        <div>
            <div className="container p-3 mt-5 pt-5">
                <h5 className="d-inline-block mb-3 mt-5">UPDATE CATEGORY</h5>
                <div className="form-group">
                    <label htmlFor="name">Category Name</label>
                    <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="enter name"
                        value={category_name}
                        onChange={(e) => setCategory_name(e.target.value)}
                    />
                </div>

                <div className="form-group">
          <label htmlFor="name"> Update Category Image</label>
          <input
            type="file"
            className="form-control"
            id="image"
            onChange={(e) =>{ if(e.target.files)
                setCategory_image(e.target.files[0]   )
              }}
            required={true}
          />
        </div>



                {/* <div className="form-group">
                    <label htmlFor="name"> Category image</label>
                    <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="enter image url"
                        value={category_image}
                        onChange={(e) => setCategory_image(e.target.value)}
                    />
                </div> */}


                <div >
                    {/* <select value={status} onChange={(e) => setStatus(e.target.value)} class="custom-select">
                        <option selected>Open this select menu</option>
                        <option value="ACTIVE">Active</option>
                        <option value="INACTIVE">InActive</option>
                        <option value="TERMINATED">Terminated</option>
                    </select> */}
                    {isError && (
                        <small className="mt-3 d-inline-block text-danger">
                            Something went wrong. Please try again later.
                        </small>
                    )}
                    <button style={{ fontWeight: "bold" }}
                        type="submit"
                        className="btn  btn-warning mt-3"
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        {loading ? "Loading..." : "Update"}
                    </button>

                </div>
            </div >
        </div >
    );
}

export default UpdateCategory;
