import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useHistory,Link } from 'react-router-dom';
// import copy from "../Components/image/copy.png"
import { ToastContainer, toast } from 'react-toastify';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import 'react-toastify/dist/ReactToastify.css';
import copy from 'copy-to-clipboard';
import moment from 'moment';
const token = (localStorage.getItem("tok"));
axios.defaults.headers.common['adminauthtoken'] = token;

// const initialstate = {
//     value: '',
//     copied: false,
//   };
function Orders(props) {
    const [buttonText, setButtonText] = useState("copy");
    const [btn,setBtn]=useState(true)
    const [data, setData] = useState(false);
    const [orderlist, setOrderlist] = useState([])
    const[trackingId,setTracking_Id]=useState("")
    const[id,setOrder_Id]=useState("")
    const[courierName,setCourier_name]=useState("")
    const [status, setStatus] = useState("")
    const [select, setSelect] = useState(false)
  const[order_status,setOrder_status]=useState("")
    const[clip,setClip]=useState({
        value : '',
        copied: false
    });
    const [isError, setIsError] = useState(false);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        axios.post(`${process.env.REACT_APP_API}/admin/getallOrders`)
            .then((res) => {
                setOrderlist(res.data.data)
            })
    }, [select])

    const handleSubmit = (e) => {
        
        e.preventDefault()
        setLoading(true);
        const data = {
            trackingId,
            courierName,
            id

        };
        // const formdata = new FormData()
        // Object.entries(data).map(data=>{ formdata.append(data[0],data[1])})

        axios
            .post(`${process.env.REACT_APP_API}/admin/updateOrders`, data)
            .then((res) => {
                setData(res.data);
                setOrder_Id("")
                setTracking_Id("");
                setCourier_name("");
                setLoading(false);
                toast.success("Information Updated successfully!", { autoClose: 2000 })
    });
}



    function updateorder(orderId, orderStatus) {
        axios.post(`${process.env.REACT_APP_API}/admin/updateOrders`, { orderId, orderStatus })
            .then((res) => {
             
                setSelect(!select)
                toast.success("Updated successfully!", { autoClose: 2000 })
                // window.location.reload();
                
            })
    }



    function check(orderlist){
        if(Array.isArray(orderlist))
        return orderlist;
        else
        return([])
      }

    return (
        
        <div className="container-fluid mt-5 pt-5">
              
            <ToastContainer />


            <div>
                <h3 className="pt-3 mt-3" > Orders</h3>
                <table class="table  mt-3">
                    <thead >
                        <td style={{ textAlign: "center" }}>S No</td>
                        <td style={{ textAlign: "center" }}>Order Id</td>
                        <td style={{ textAlign: "center" }}>Txn Status </td>
                        <td style={{ textAlign: "center" }}> Total Payment</td>
                        {/* <td style={{ textAlign: "center" }}> Paid Payment</td> */}
                        <td style={{ textAlign: "center" }}> TimeStamp</td>
                        {/* <td style={{ textAlign: "center" }}> Payment Status</td> */}
                        <td style={{ cursor: "pointer" }}>  Action</td>
                        <td ><select class="custom-select" onChange={(e) => setStatus(e.target.value)} style={{ color: "white", backgroundColor: "black", border: "none", outline: 0 }}>
                            <option  >Filter Orders</option>
                            <option value="DECLINE">Decline</option>
                            <option value="PENDING">Pending</option>
                            <option value="ACCEPTED">Accepted</option>
                            <option value="PACKAGING">Packaging</option>
                            <option value="DISPATCHED">Dispatched</option>
                            <option value="DELIVERED">Delivered</option>
                        </select> </td>
                    </thead>
                    <tbody>
                        {check(orderlist).filter(data => data.order_status === status || !status).map((x, index) => (
                            <tr>
                                <td style={{ textAlign: "center" }}>{index + 1}</td>
                                <td style={{ textAlign: "center" }} >{x.order_id} &nbsp;
                                <span className={btn == index ? "badge badge-success" : "badge badge-warning"} style={{ marginLeft: "10px", cursor: "pointer" }}  
                                        onClick={() => { return navigator.clipboard.writeText(x.order_id), (setBtn(index)) }}
                                >
                             {btn == index ?  'Copied' : "Copy" }    </span>
                                </td>
                                <td style={{ textAlign: "center" }}>{x.payment_status}</td>
                                <td style={{ textAlign: "center" }}>Rs.{x.total_payment}/-</td>
                                {/* <td style={{ textAlign: "center" }}>Rs.{x.paidAmount }/-</td> */}
                                <td style={{ textAlign: "center" }}>{moment(x.createdAt).format('Do MMM YYYY')}</td>
                                <td><Link to={"/admin/viewOrders/" + x.order_id} style={{ cursor: "pointer" }} className="badge badge-success"><i className="bx bx-show"> </i> View </Link></td>
                                <td> <select class="custom-select" onChange={(e) => updateorder(x.order_id, e.target.value)} >
                                    <option selected={x.order_status === "ACCEPTED"} value="ACCEPTED">Accepted</option>
                                    <option selected={x.order_status === "PENDING"} value="PENDING">Pending</option>
                                    <option selected={x.order_status === "DECLINE"} value="DECLINE">Decline</option>
                                    <option selected={x.order_status === "PACKAGING"} value="PACKAGING">Packaging</option>
                                    <option selected={x.order_status === "DISPATCHED"} value="DISPATCHED">Dispatched</option>
                                    <option selected={x.order_status === "DELIVERED"} value="DELIVERED">Delivered</option>
                                </select></td>
                            </tr>
                        ))}
                        {check(orderlist).length === 0 && (
                            <tr>
                                <td className="text-center mt-5" colSpan="4">
                                    <b className="mt-5" style={{ textAlign: "center" }}> No data found to display</b>
                                </td>
                            </tr>
                        )}

                    </tbody>
                </table>
            </div >
        </div >
    )
}

export default Orders
