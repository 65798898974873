import React, { useState } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Link, useLocation, useHistory } from "react-router-dom";

const token = (localStorage.getItem("tok"));

function UpdateBanner(props) {
    const history = useHistory();
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();
    const [status, setStatus] = useState("");
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [data, setData] = useState(null);
    const handleSubmit = () => {
        history.push("/admin/Banner")
        setLoading(true);
        setIsError(false);
        const image_id = query.get("id");
        const data = {
            image_id,
            status
        };
        axios
            .post(`${process.env.REACT_APP_API}/admin/removeBanners`, data, { headers: { "adminauthtoken": `${token}` } })
            .then((res) => {
                setData(res.data);
                setStatus("");
                setLoading(false);

            })
            .catch((err) => {
                setLoading(false);
                setIsError(true);
            });
    };

    return (
        <div>
            <div className="container p-3 mt-5" >
                <h5 className="d-inline-block mb-3">UPDATE BANNER</h5>

                <div>
                    <select value={status} onChange={(e) => setStatus(e.target.value)} class="custom-select">
                        <option selected>Open this select menu</option>
                        <option value="ACTIVE">Active</option>
                        <option value="INACTIVE">InActive</option>
                        <option value="TERMINATED">Terminated</option>
                    </select>

                    {isError && (
                        <small className="mt-3 d-inline-block text-danger">
                            Something went wrong. Please try again later.
                        </small>
                    )}
                    <button
                        type="submit"
                        className="btn btn-primary mt-3"
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        {loading ? "Loading..." : "Submit"}
                    </button>

                </div>
            </div >
        </div >
    );
}

export default UpdateBanner;
