import React from 'react'
// import imgg from '../404.svg'
function Notfound() {
    return (
        <div>
            <center>
                <img className="mt-5" src="" width="500px" />
                <h3 className="mt-5"> Page Not Found!</h3>
            </center>
        </div>
    )
}

export default Notfound