
import React, { useState, useEffect } from "react";
import axios from 'axios'
// react plugin used to create charts
import { Line, Pie } from "react-chartjs-2";
import { FcBusinessman } from "react-icons/fc";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
// core components
import {
  dashboard24HoursPerformanceChart,
  dashboardEmailStatisticsChart,
  dashboardNASDAQChart,
} from "variables/charts.js";
import { Redirect } from "react-router";
const token = (localStorage.getItem("tok"))
// {
//   token === null ? <Redirect to="/" /> : <Redirect to="/admin/dashboard" />
// }

function Dashboard(props) {
  // if (token) {
  //   props.history.push('/admin/dashboard')
  // }
  const [catlist, setCatlist] = useState([]);
  const [prolist, setProlist] = useState([]);
  const [cusList, setCusList] = useState([]);
  const [orderlist, setOrderlist] = useState([]);
  useEffect(() => {

    axios.get(`${process.env.REACT_APP_API}/admin/getCategory`, { headers: { "adminauthtoken": `${token}` } }).then((res) => {
      setCatlist(res.data.data);
    });

    axios.post(`${process.env.REACT_APP_API}/admin/getProduct`, {}, { headers: { "adminauthtoken": `${token}` } }).then((res) => {
      setProlist(res.data.data);
    });
    axios.get(`${process.env.REACT_APP_API}/admin/fetchall`, { headers: { "adminauthtoken": `${token}` } }).then((res) => {
      setCusList(res.data.data);
    });

    axios.post(`${process.env.REACT_APP_API}/admin/getallOrders`)
      .then((res) => {
        setOrderlist(res.data.data)
      })

  }, [])


  function check(orderlist){
    if(Array.isArray(orderlist))
    return orderlist;
    else
    return([])
  }
  return (
    <>
      <div className="content">
        <Row>
          <Col lg="3" md="6" sm="6">
            <Card style={{ backgroundColor: "white" }} className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i style={{ color: "black" }} class='bx bx-user'></i>
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category" style={{ color: "black" }}>Customers</p>
                      <CardTitle tag="p" style={{ color: "black" }}>{cusList.length}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr style={{ color: "black" }} />
                <div className="stats">
                  {/* <i className="fas fa-sync-alt" /> Update Now */}
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card style={{ backgroundColor: "white" }} className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i style={{ color: "black" }} class='bx bx-list-ul'></i>
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category" style={{ color: "black" }}>Category</p>
                      <CardTitle tag="p" style={{ color: "black" }}>{catlist.length}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr style={{ color: "black" }} />
                <div className="stats">
                  {/* <i className="far fa-calendar" /> Last day */}
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card style={{ backgroundColor: "white" }} className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i style={{ color: "black" }} class='bx bx-box'></i>
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category" style={{ color: "black" }}>Products</p>
                      <CardTitle tag="p" style={{ color: "black" }}>{prolist.length}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr style={{ color: "black" }} />
                <div className="stats">
                  {/* <i className="far fa-clock" /> In the last hour */}
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card style={{ backgroundColor: "white" }} className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i style={{ color: "black" }} class='bx bx-cart' ></i>
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category" style={{ color: "black" }}>Orders</p>
                      <CardTitle tag="p" style={{ color: "black" }}>{check(orderlist).length}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr style={{ color: "black" }} />
                <div className="stats">
                  {/* <i className="fas fa-sync-alt" /> Update now */}
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>


      </div>
    </>
  );
}

export default Dashboard;
