import React, { useState, useEffect } from "react";
import axios from 'axios'
import { BrowserRouter as Router, Route, Link, useLocation, useHistory } from "react-router-dom";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col,
} from "reactstrap";

const token = (localStorage.getItem("tok"));
function Customerlist(props) {
    const [cusList, setCusList] = useState([]);
    let query = useQuery();
    const history = useHistory();
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const id = query.get("id")
    useEffect(() => {
        axios.post(`${process.env.REACT_APP_API}/admin/fetchSingleCustomer`, { id: id }, { headers: { "adminauthtoken": `${token}` } })
            .then((res) => {
                setCusList(res.data.data);
                console.log("res",res.data.data);
            });
    }, []);
    return (
        <>
            <div className="content">
                <Row >
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">Customer Table</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <div class="table-responsive">
                                    <table class="table">

                                        <tbody>
                                            <tr>
                                                <td style={{ fontWeight: "bolder" }}> User Photo </td>
                                                <td> <img src="/images/profile pic.png" width="100" height="100" /></td>
                                            </tr>
                                            <tr>
                                                <td style={{ fontWeight: "bolder" }}> UID </td>
                                                <td style={{ textTransform: "capitalize" }}><span>{cusList?.fetchedCustomer?.emp_id}</span></td>
                                            </tr>
                                            <tr>
                                                <td style={{ fontWeight: "bolder" }}> Customer Name</td>
                                                <td style={{ textTransform: "capitalize" }}><span>{cusList?.fetchedCustomer?.customername}</span></td>
                                            </tr>


                                            <tr> <td style={{ fontWeight: "bolder" }}>Email</td>
                                                <td >{cusList?.fetchedCustomer?.email}</td>
                                            </tr>
                                            <tr> <td style={{ fontWeight: "bolder" }} >Dob</td>
                                                <td >{cusList?.fetchedCustomer?.dob}</td>
                                            </tr>
                                            <tr> <td style={{ fontWeight: "bolder" }}>Gender</td>
                                                <td >{cusList?.fetchedCustomer?.gender}</td>
                                            </tr>
                                            <tr> <td style={{ fontWeight: "bolder" }}>Location</td>
                                                <td >{cusList?.fetchedCustomer?.location}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ fontWeight: "bolder" }}>Phone</td>
                                                <td>{cusList?.fetchedCustomer?.phone}</td>
                                            </tr>

                                        </tbody>

                                    </table>
                                </div>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">Shipping Address</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <div class="table-responsive">
                                    <table class="table">  
                                    <tr>
                                        <th > S No </th>
                                        <th > Street </th>
                                        <th > Landmark </th>
                                        <th > City</th>
                                        <th > State </th>
                                        <th > Zipcode </th>
                                    </tr>
                                        {Array.isArray(cusList?.fetchAddress) && cusList?.fetchAddress?.length != 0 ?cusList?.fetchAddress?.map((x, index) => {

                                            return (
                                                <tbody>
                                                   
                                                    <tr>
                                                        <td style={{ textTransform: "capitalize" }}> {index + 1} </td>
                                                        <td style={{ textTransform: "capitalize" }}> {x?.street} </td>
                                                        <td style={{ textTransform: "capitalize" }}> {x?.landmark} </td>
                                                        <td style={{ textTransform: "capitalize" }}> {x?.city}</td>
                                                        <td style={{ textTransform: "capitalize" }}> {x?.state} </td>
                                                        <td style={{ textTransform: "capitalize" }}> {x?.zipcode} </td>
                                                    </tr>

                                                </tbody>

                                            )

                                        }
                                        ):(
                                           
                                            <h3 className="container text-center" style={{fontWeight:"bold"}}>"No Address Found"</h3>
                                          )}
                                    </table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div >
        </>
    );
}

export default Customerlist;
