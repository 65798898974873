import React, { useState, useEffect } from "react";
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import 'react-toastify/dist/ReactToastify.css';
// reactstrap components

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
} from "reactstrap";

const token = (localStorage.getItem("tok"));
function Tables(props) {
  const [status,setStatus]=useState("");
  const [refresh,setReferesh]=useState(false);
  const [cusList, setCusList] = useState([]);
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}/admin/fetchall`, { headers: { "adminauthtoken": `${token}` } }).then((res) => {
      setCusList(res.data.data);
   
    });
  }, [refresh]);

  const updateStatus = (value,id) => {
  axios.post(`${process.env.REACT_APP_API}/admin/updateSingleCustomer`,
            {
            status:value,
           id:id
            }
        ).then(res => {
          setReferesh(!refresh)
          toast.success("Updated successfully!", { autoClose: 2000 })
        
        })
          }


  function update(id) {

    props.history.push("/admin/Customerlist?id=" + id)
  }

  return (
    <>
      <div   className="content">
      <ToastContainer />
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Customer Table</CardTitle>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-secondary">
                    <tr>
                      <th style={{ textAlign: "center" }}>UId</th>
                      <th style={{ textAlign: "center" }}>Customer Image</th>
                      <th style={{ textAlign: "center" }}>Name</th>
                      <th style={{ textAlign: "center" }}>email</th>
                      <th style={{ textAlign: "center" }}>phone</th>
                      <th style={{ textAlign: "center" }}>Status</th>
                      <th style={{ textAlign: "start" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cusList.map((x) => {console.log(); return (
                      <tr>
                        <td style={{ textAlign: "center" }}>{x.emp_id}</td>
                        <td style={{ textAlign: "center" }}>
                          <img src="/images/profile pic.png" width="50" height="50" />
                        </td>
                        <td style={{ textTransform: "capitalize", textAlign: "center" }}>{x.customername}</td>
                        <td style={{ textAlign: "center" }}>{x.email}</td>
                        <td style={{ textAlign: "center" }}>{x.phone}</td>
                        <td style={{ textTransform: "capitalize", textAlign: "center" }}>
                        
                        {/* <select
              value={status}
              onChange={(e,index) => updateStatus(e.target.value,x.id)}
              class="form-select"
              defaultValue={"INACTIVE"}
            >
              <option value="ACTIVE">Active</option>
              <option value="INACTIVE">Inactive</option>
              <option value="TERMINATED">Terminated</option>
            </select>  */}

            <select class="form-select"
            defaultValue={x.status}
              onChange={(e,index) => updateStatus(e.target.value,x.id)} aria-label="Default select example">
  <option selected>User Status</option>
  <option value="ACTIVE">Active</option>
              <option value="INACTIVE">InActive</option>
              <option value="TERMINATED">Terminated</option>
</select>

           </td>
             {/* {x.status === "ACTIVE" ? <span className="badge badge-success">Active</span>: x.status === "INACTIVE" ? <span className="badge badge-success">Active</span> :  x.status === "TERMINATE" ? <span className="badge badge-success">terminate</span> :false } */}
                        {/* <td style={{ textTransform: "capitalize", textAlign: "center" }}>{x.status === "INACTIVE" ? <span className="badge badge-warning">INACTIVE</span>:false  }</td> */}
                        {/* <td style={{ textTransform: "capitalize", textAlign: "center" }}>{x.status === "TERMINATED" ? <span className="badge badge-danger">TERMINATED</span>:false  }</td> */}
                        <td><span className="badge badge-success" style={{ cursor: "pointer", textAlign: "center" }} onClick={() => update(x.id)} ><i class='bx bx-show'>  </i> view</span></td>
                      </tr>
                    )})}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Tables;
