
import React from "react";
import { NavLink } from "react-router-dom";
import { Nav } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import logo from "../Sidebar/logo.png";
import { borderBottom } from "@mui/system";

var ps;

function Sidebar(props) {
  const sidebar = React.useRef();
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });
  return (
    <div
      className="sidebar"
      data-color={props.bgColor}
      data-active-color={props.activeColor}
    >
      <div className="logo">
       
          {/* <div className="logo-img">
            <img src={logo} alt="react-logo" style={{ width:"140px", display: "block",marginLeft: "auto",marginRight: "auto"}}/>

          </div> */}
          <div className="logo">
        <a
          href="/"
          className="simple-text logo-mini"
        >
          <div className="logo-img">
            <img src={logo} alt="react-logo" />

          </div>
        </a>
        <a
          href="/"
          className="simple-text logo-normal"
          style={{ fontWeight: "bold", fontSize: "22px",color:"#28a745" }}  >
          UyirTheeni
        </a>
      </div>
       <hr style={{ borderBottom:"2px solid white"}}/>
        {/* <a
          href=""
          className="simple-text logo-normal"
          style={{ fontWeight: "bold", fontSize: "16px" }}  >
          Uyirtheeni Grocery
        </a> */}
      </div>
      <div className="sidebar-wrapper" ref={sidebar}>
        <Nav>
          {props.routes.map((prop, key) => {

            return prop.sidebar ? (
              <li
                className={
                  activeRoute(prop.path) + (prop.pro ? " active-pro" : "")
                }
                key={key}
              >
                <NavLink
                  to={prop.layout + prop.path}
                  className="nav-link"
                  activeClassName="active"
                >
                  <i className={prop.icon} />
                  <p style={{ textTransform: "capitalize", fontWeight: "bold", fontSize: "18px" }}>{prop.name}</p>
                </NavLink>
              </li>
            ) : false;
          })}
        </Nav>
      </div>
    </div>
  );
}

export default Sidebar;
