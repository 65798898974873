import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "bootstrap/dist/css/bootstrap.min.css";
const token = (localStorage.getItem("tok"));
function CreateProductVariant() {
    const [prolist, setProlist] = useState([]);
    const [product_id, setProduct_id] = useState("");
    const [product_name, setProduct_name] = useState("");
    const [quantity, setQuantity] = useState("");
    const [actual_price, setActual_price] = useState("");
    const [discount_price, setDiscount_price] = useState("");
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState("");
    const [aerr, setAerr] = useState("");
    const [isError, setIsError] = useState(false);
    const [data, setData] = useState(null);
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true);
        setIsError(false);
        const data = {
            product_id,
            quantity,
            actual_price,
            discount_price,
        };
        axios
            .post(`${process.env.REACT_APP_API}/admin/addProductVariant`, data, { headers: { "adminauthtoken": `${token}` } })
            .then((res) => {
                setData(res.data);

                setQuantity("");
                setActual_price("");
                setDiscount_price("")
                toast.success("Product Variant Created!", { autoClose: 2000 })
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                setIsError(true);
            });
    };
    useEffect(() => {
        axios.post(`${process.env.REACT_APP_API}/admin/getProduct`, {}, { headers: { "adminauthtoken": `${token}` } }).then((res) => {
            setProlist(res.data.data);
    
           
            
           
        });
    }, [])
    return (
        <div><ToastContainer />
            <form onSubmit={handleSubmit}>
                <div className="container p-3 mt-5" >
                    <h5 className="d-inline-block mb-3 mt-5">CREATE PRODUCT VARIANT</h5>
                    <div ><label>Product Name</label>
                        {/* <select class="custom-select" value={product_id} onChange={(e) => setProduct_id(e.target.value)}> */}
                        <select class="custom-select" style={{ textTransform: "capitalize" }} onChange={(e) => {
                            e.preventDefault()
                            if (e.target.value) {
                                setProduct_id(e.target.value)
                                   
                            }
                        }}>   <option> Select Product</option>
                            {prolist.filter(data => data.status === "ACTIVE").map((x, index) => (
                                <option value={x.id}>{x.product_name}</option>
                            ))}
                        </select>
                        <div className="form-group">
                            <label htmlFor="name"> Quantity in Gm</label>
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                placeholder="Ex: 250"
                                value={quantity}
                                onChange={(e) => setQuantity(e.target.value)}
                                required={true}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="name"> Actual Price</label>
                            <input
                                type="number"
                                className="form-control"
                                id="name"
                                placeholder="Enter Price"
                                value={actual_price}
                                onChange={(e) => {
                                    setActual_price(e.target.value)
                                    if (Number(discount_price) < e.target.value) {
                                        setErr("")
                                        setAerr("")
                                    }
                                    else {
                                        setAerr("Actual price must be Greater than discount price");
                                    }
                                }
                                } required={true}
                            />  <span style={{ color: "red" }}>{aerr}</span>
                        </div>
                        <div className="form-group">
                            <label htmlFor="name"> Discount Price</label>
                            <input
                                type="number"
                                className="form-control"
                                id="name"
                                placeholder="Discount Price"
                                value={discount_price}
                                onChange={(e) => {
                                    setDiscount_price(e.target.value)
                                    if (Number(e.target.value) < actual_price) {
                                        setErr("")
                                        setAerr("")
                                    }
                                    else {
                                        setErr("Discount price must be less than actual price");
                                    }
                                }} required={true}
                            />
                            <span style={{ color: "red" }}>{err}</span>
                        </div>
                        {isError && (
                            <small className="mt-3 d-inline-block text-danger">
                                Something went wrong. Please try again later.
                            </small>
                        )}
                        <button style={{ fontWeight: "bold" }}
                            type="submit"

                            className="btn btn-block btn-success mt-3"
                            disabled={loading}
                        >
                            {loading ? "Loading..." : "Submit"}
                        </button>
                    </div>
                </div >
            </form>
        </div >
    );
}

export default CreateProductVariant;
